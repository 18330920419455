import "./ListContainer.css";
import { useEffect, useState } from "react";
import Search from "../../Helpers/Search/Search";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import axios from "axios";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";
import { useParams } from "react-router-dom";

export default function ListContainer(props) {

    const { list, activeElement, handleActiveElement, iconTrue, iconFalse, toggleLocking,getAssignmentList, downloadIcon, handleDownload, handleChange, showSearch=false } = props;
    const [search, setSearch] = useState("")
    const [openList, setOpenList] = useState(true);
    return (
        <div>
            <div className={openList ? "students-container-list-eval" : "students-container-list-eval hide"}>
                <div className="students-container-list-options">
                    {showSearch && <Search callback={setSearch} />}
                    <div onClick={() => { setOpenList(!openList) }} className="students-container-list-toggle-eval">
                        {openList ? <ChevronLeftRoundedIcon className="students-container-list-toggle-icon-eval" /> :
                            <ChevronRightRoundedIcon className="students-container-list-toggle-icon-eval" />}
                    </div>
                </div>
                <div className="students-container-list-child">
                    {list?.filter(e => e.title?.toLowerCase().includes(search.toLowerCase()))?.map((e, index) => <ListElement
                        key={e.id || index}
                        activeElement={activeElement}
                        handleActiveElement={handleActiveElement}
                        data={e}
                        icon={e?.iconValue === true ? iconTrue : iconFalse}
                        downloadIcon={e?.downloadIcon}
                        toggleLocking={toggleLocking}
                        getAssignmentList={getAssignmentList}
                        handleDownload={handleDownload}
                        handleChange={handleChange}
                    />
                    )}
                </div>
            </div>
            <div className="students-container-list-toggle-hidden-menu-eval" style={{ display: openList ? "none" : "block" }}>
                <div onClick={() => { setOpenList(!openList) }} className="students-container-list-toggle-eval">
                    {openList ? <ChevronLeftRoundedIcon className="students-container-list-toggle-icon-eval" /> :
                        <ChevronRightRoundedIcon className="students-container-list-toggle-icon-eval" />}
                </div>
            </div>
        </div>
    );
}

function ListElement(props) {

    const {auth,setAuth} = useAuth()
    const {stationId} = useParams()
    function handleLock() {
        try {
            if (auth) {
                axios.post(`/api/assignment/lockAssignment`, {
                    assignment: props?.data?.id
                }).then(e => {
                    toast.success(`Assignment ${props?.data?.iconValue?"Unlocked":"Locked"}`)
                    props?.getAssignmentList(stationId);
                    
                })
                    .catch(e => {
                        toast.error("Error: Please try again.")
                        console.log(e);
                    })
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data === "Token Expired") {
                alert("Your session is expired. Please Log in again.");
            }
            localStorage.removeItem("user");
            setAuth({});
        }
    }

    return (
        <div
            className={props?.activeElement === props?.data?.id ? "student-active" : "student"}
            onClick={() => {props?.handleActiveElement(props?.data?.id)
                if (props?.handleChange){
                    props?.handleChange(props?.data?.id)
                }
            }
            }
        >
            <div className="student-details-container"  >
                <div className="student-details-container-1">
                    <div className="student-name">{props.data.title}</div>
                    <div className="student-station">{props.data.body}</div>
                </div>
                <div style={{display: "flex", gap:"0.5rem"}}>
                    <div onClick={(e)=>{
                        e.stopPropagation()
                        if (props?.downloadIcon){
                            props?.handleDownload(props?.data?.id)
                        }
                    }}>
                        {props?.downloadIcon}
                    </div>
                    <div onClick={(e) => {
                        e.stopPropagation()
                        if (props?.toggleLocking) {
                            handleLock()
                        }}}
                    >
                        {props?.icon}
                    </div>
                </div>
            </div>
        </div>
    );
}