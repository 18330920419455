import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import ListContainer from "../Evaluation/ListContainer/ListContainer";  
import Loading from "../Helpers/Loading/Loading";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import MyPDFViewer from "./PdfEmbed";
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from "react-toastify";
import axios from "axios";


export default function MentorReport(props) {
  const { students, getStudentList } = props;
  const { auth, setAuth } = useAuth();
  const { stationId } = useParams();
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const reports = [
  { _id: "midsem", name: "Midsem Report"},
  { _id: "endsem", name: "Endsem Report"},
];
  useEffect(() => {
    if (reports.length > 0 && selectedReport === "") {
      setSelectedReport(reports[0]._id);
    }
  }, [reports]);
  useEffect(()=>{
    getStudentList(stationId);
  },[])
  // useEffect(() => {
  //   if (students.length > 0 && selectedStudent === "") {
  //     setSelectedStudent(students[0]._id);
  //   }
  // }, [students]);
  const handleDownload = async (reportType) =>{
    let toastId = toast.loading("Downloading Report...");

    try{
      let res = await axios.post(`/api/report/downloadReportForStation`,
         {station: stationId, type: reportType},
         {responseType: 'blob'});
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${stationId}-${reportType}.zip`);
        document.body.appendChild(link);
        link.click();
        toast.dismiss(toastId);
        toast.success("Report downloaded successfully");
    }catch(error){
      toast.dismiss(toastId);
      toast.error("Error downloading report");
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }
  }

  const handleGetStudentReport = async (studentEmail) =>{
    setLoading(true);
    try{
      let res = await axios.post(`/api/report/downloadReportForMentor`, {type: selectedReport, studentEmail: studentEmail},{responseType: 'blob'});
      if (res.status === 200) {
        if (res.headers["content-type"] === "application/pdf") {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          setPdfUrl(url);
        }else{
          const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${selectedReport}.${res.headers["content-type"].split('/').pop() ==="octet-stream" ? "docx" : res.headers["content-type"].split('/').pop()}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setPdfUrl("docx");
        }
      }else{
        setPdfUrl(null);
        setLoading(false);
      }
      }catch(error){
      setPdfUrl(null);
      setLoading(false);
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }finally{
      setLoading(false);
    }
  };
  return (
    <div className="students-container">
      {reports.length > 0 ? (
        <ListContainer
          list={reports.map((e) => ({
            id: e._id,
            title: e.name,
            body: "",
            iconValue: e.isLocked,
            downloadIcon: <DownloadIcon/>
          }))}
          activeElement={selectedReport}
          handleActiveElement={setSelectedReport}
          handleDownload = {handleDownload}
          handleChange={handleGetStudentReport}
          toggleLocking={true}
          getReportList={() => {}}
        />
      ) : (
        <Loading />
      )}
      {reports.length > 0 && students.length > 0 ? (
        <ListContainer
          list={students.map((e) => ({
            id: e._id,
            title: e.name,
            body: e.bitsID,
            // iconValue: submissions.some(s => s.report === selectedReport && s.student === e._id),
          }))}
          activeElement={selectedStudent}
          handleActiveElement={setSelectedStudent}
          // iconTrue={<TaskAltRoundedIcon style={{ color: "#57cc57" }} />}
          // iconFalse={<ErrorOutlineRoundedIcon style={{ color: "red" }} />}
          toggleLocking={false}
          handleChange={handleGetStudentReport}
        />
      ) : (
        <Loading />
      )}
      <div className="students-container-details">
      <div style={{padding: "2rem", height:'100%'}}>
          {loading ? (<Loading />):(<MyPDFViewer pdfUrl={pdfUrl}/>)}
        </div>
      </div>
    </div>
  );
}

function ReportDetails(props) {
  const { selectedReport, selectedStudent, reports, submissions } = props;
  const [report, setReport] = useState();
  const [pdfUrl, setPdfUrl] = useState();

  useEffect(() => {
    const selectedReportData = reports.find(r => r._id === selectedReport);
    setReport(selectedReportData);
  }, [selectedReport, reports]);

  useEffect(() => {
    const submission = submissions.find(s => s.report === selectedReport && s.student === selectedStudent);
    setPdfUrl(submission ? submission.pdfUrl : null);
  }, [selectedStudent, selectedReport, submissions]);


  return report ? (
    <div className="student-diary">
      <div className="diary-student-profile">
        <div className="diary-student-profile-details">
          <div className="student-name">{report?.name}</div>
          <div className="student-station">{selectedStudent}</div>
        </div>
        <div className="diary-options">
          <div className="diary-options-lock-show">
            {report?.isLocked ? <LockRoundedIcon /> : <LockOpenRoundedIcon />}
          </div>
        </div>
      </div>
      <div className="diary-question-container">
        {pdfUrl ? (
          <div className="pdf-container">
            <div className="pdf-preview">
              <MyPDFViewer pdfUrl={pdfUrl}/>
            </div>
          </div>
        ) : (
          <div className="student-details-empty">
            <p>Report not submitted by the Student.</p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <Loading />
  );
}