import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useParams } from "react-router-dom";
import ListContainer from "./ListContainer/ListContainer";
import Loading from "../Helpers/Loading/Loading";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import LockRoundedIcon from "@mui/icons-material/LockRounded";
import TaskAltRoundedIcon from "@mui/icons-material/TaskAltRounded";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import DownloadIcon from '@mui/icons-material/Download';
import { toast } from "react-toastify";
import Grading from "../Grading/Grading";
import MyPDFViewer from "../Report/PdfEmbed";
import axios from "axios";
import { Button } from "@mui/material";
import FileUploadPreview from "../Helpers/FileUploadPreview/FileUploadPreview";
import {styled} from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export default function Evaluation(props) {
  const { auth, setAuth } = useAuth();
  const [selectedReport, setSelectedReport] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [students, setStudents] = useState([]);
  const [midsemTemplate, setMidsemTemplate] = useState(null);
  const [endsemTemplate, setEndsemTemplate] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [template, setTemplate] = useState(null);
  const [loading, setLoading] = useState(false);
  const components = [{_id: "midsem", name: "Midsem"},{_id: "endsem", name: "Endsem"}];
  const options = [{_id: "Report", name: "Report"},{_id: "Grading", name: "Grading"}];
    const getAllStudents = async () =>{
      try{
          if (auth?.userType !== "Student") {
            const data = await axios.post(`/api/grades/getScoresForAllStationsOfMentor`, auth);
            setStudents(data?.data);
          }
      }catch(error){
         console.log(error);
        if (error.response.data === "Token Expired") {
          alert("Your session is expired. Please Log in again.");
          localStorage.removeItem("user");
          setAuth({});
        }
      }
    }
  useEffect(() => {
    // console.log(students)
    if (components.length > 0 && selectedReport === "") {
      setSelectedReport(components[0]._id);
    }
    // console.log(props)
  }, [components]);
  useEffect(()=>{
    getAllStudents();
  },[])
  useEffect(() => {
    if (students.length > 0 && selectedOption === "") {
      setSelectedOption();
    }
  }, [students]);
  const handleDownload = async () => {
    let toastId = toast.loading("Downloading Report");
    try {
      let res = await axios.post('/api/report/downloadAllReports', { type: selectedReport }
        , { responseType: 'blob' }
      );
      if (res.status === 200) {
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/zip' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download',`${selectedReport}.zip`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        toast.success('Report downloaded successfully');
      } else {
        toast.error('Error while downloading report');
      }
    } catch (error) {
      toast.error('Error while downloading report');
    } finally {
      toast.dismiss(toastId);
    }
  }

  const handleGetTemplate = async (e, reportType) => {
    setSelectedStudent();
    setLoading(true);
    try {
      let res = await axios.post(`/api/report/downloadTemplate`, {
        type: selectedReport
      }, {responseType: 'blob'});
      let contentType = res.headers['content-type'];
      // alert(contentType);
      if (res.status === 200) {
        if (contentType === "application/pdf") {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          if (reportType === "midsem") {
            setMidsemTemplate(url);
            setPdfUrl(url);
          } else {
            setEndsemTemplate(url);
            setPdfUrl(url);
          }
      } else {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }));
          const link = document.createElement('a');
          link.href = url;
          link.download =  `${selectedReport}-template.docx`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          toast.success('Template downloaded successfully');
          if (selectedReport === "midsem") {
            setMidsemTemplate(url);
            setPdfUrl("docx");
          } else {
            setEndsemTemplate(url);
            setPdfUrl("docx");
          }
      }
      } else {
        toast.error('Template not uploaded. Please upload Template');
      }
    } catch (err) {
      toast.error('Template not uploaded. Please upload Template');
      console.error(err);
    }finally{
      setLoading(false);
    }
  }
  const handleRemoveFile = (reportType) => {
    if (reportType === "midsem") {
      setMidsemTemplate(null);
      setTemplate(null);
      document.getElementById('midsem-file-input').value = '';
    } else {
      setEndsemTemplate(null);
      setTemplate(null);
      document.getElementById('endsem-file-input').value = '';
    }
  };
  const handleFileUpload = async(event, reportType) => {

    const toastId = toast.loading("Uploading file");
    const file = event.target.files[0];
    if (file && (file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      if (reportType === "midsem") {
        setMidsemTemplate(file);
      } else {
        setEndsemTemplate(file);
      }
      try {
        if (!file) {
          toast.error('No file uploaded');
          return;
        }
        if (!selectedReport || !(selectedReport === 'midsem' || selectedReport === 'endsem')) {
          toast.error('No or invalid report type mentioned');
          return;
        }
        const formData = new FormData();
        formData.append('report', file);
        formData.append('type', selectedReport);
        let res = await axios.post(`/api/report/uploadTemplateForAll`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (res.status === 200) {
          toast.success('File uploaded successfully');
          if (reportType === "midsem") {
            handleRemoveFile("midsem");
          } else {
            handleRemoveFile("endsem");
          }
        } else {
          toast.error('Error while uploading file');
          if (reportType === "midsem") {
            handleRemoveFile("midsem");
          } else {
            handleRemoveFile("endsem");
          }
        }
      } catch (err) {
        toast.error('Error while uploading to server');
        if (reportType === "midsem") {
          handleRemoveFile("midsem");
        } else {
          handleRemoveFile("endsem");
        }
        console.error(err);
      } finally {
        toast.dismiss(toastId);
        handleGetTemplate(selectedReport);
      }
    } else if (file && (file.type !== "application/pdf" || file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document")) {
      toast.error(`Please upload a PDF/word file`);
      if (reportType === "midsem") {
        handleRemoveFile("midsem");
      } else {
        handleRemoveFile("endsem");
      }
    }
  };

  const handleGetStudentReport = async (studentEmail) =>{
    setLoading(true);
    try{
      let res = await axios.post(`/api/report/downloadReportForMentor`, {type: selectedReport, studentEmail: studentEmail},{responseType: 'blob'});
      if (res.status === 200) {
        if (res.headers["content-type"] === "application/pdf") {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          setPdfUrl(url);
        }else{
          const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers["content-type"] }));
          const link = document.createElement('a');
          link.href = url;
          link.download = `${selectedReport}.${res.headers["content-type"].split('/').pop() ==="octet-stream" ? "docx" : res.headers["content-type"].split('/').pop()}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
          setPdfUrl("docx");
        }
        // const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
        // setPdfUrl(url);
      }else{
        setPdfUrl(null);
        setLoading(false);
      }
      }catch(error){
      setPdfUrl(null);
      setLoading(false);
      console.log(error);
      if (error.response.data === "Token Expired") {
        alert("Your session is expired. Please Log in again.");
        localStorage.removeItem("user");
        setAuth({});
      }
    }finally{
      setLoading(false);
    }
  };

  return (
    <div className="students-container">
      {components.length > 0 ? (
        <ListContainer
          list={components.map((e) => ({
            id: e._id,
            title: e.name,
            body: "",
            iconValue: e.isLocked,

          }))}
          activeElement={selectedReport}
          handleActiveElement={setSelectedReport}
          toggleLocking={true}
          handleChange={handleGetStudentReport}
        />
      ) : (
        <Loading />
      )}
      {components.length > 0 && options.length > 0 ? (
        <ListContainer
          list={options.map((e) => ({
            id: e._id,
            title: e.name,
            // body: e._id,
            downloadIcon: e._id === "Report" ? <DownloadIcon/>:"",
            // iconValue: submissions.some(s => s.report === selectedReport && s.student === e._id),
          }))}
          activeElement={selectedOption}
          handleActiveElement={setSelectedOption}
          handleDownload = {handleDownload}
          // downloadIcon = {selectedOption === "Report" ? <DownloadIcon/>:""}
          toggleLocking={false}
        />
      ) : (
        <Loading />
      )}
      {selectedOption && selectedOption === "Report"? (
        <>
        <ListContainer
          list={students.map((e) => ({
            id: e._id,
            title: e?.name,
            body: e?.bitsID,
          }))}
          activeElement={selectedStudent}
          handleActiveElement={setSelectedStudent}
          toggleLocking={false}
          handleChange={handleGetStudentReport}
        />
      <div className="students-container-details">
        <div className="students-container-list-options">
          <Button variant="contained" onClick={handleGetTemplate}>template</Button>
          <Button
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload {selectedReport} Template
            <VisuallyHiddenInput
              id='midsem-file-input'
              type="file"
              onChange={(e) => handleFileUpload(e, "midsem")}
              accept="application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </Button>
        </div>
        <div style={{padding: "2rem", height:'100%'}}>
          {loading ? (<Loading />):(<MyPDFViewer pdfUrl={pdfUrl}/>)}
        </div>
      </div>
      </>
        ):(<div className="students-container-details">
            <Grading stations={props?.stations} component = {selectedReport}/>
          </div>
        )}
    </div>
  );
}